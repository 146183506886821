<template>
  <div>
    <div style="width:100%;margin:5px auto;display: flow-root;">
      <div style="position: sticky;top: 0;z-index: 999;">
        <van-tabs v-model="active"
          color="#ffc002"
          @click="onClick"
          :sticky="true">
          <van-tab title="待审核"
            name="1"></van-tab>
          <van-tab title="已审核"
            name="2"></van-tab>
          <van-tab title="未通过"
            name="9"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div>
      <!-- 内容 -->
      <div class="lists">
        <!-- 列表 -->
        <div class="list"
          v-for="item in countList"
          :key="item.OMAId">
          <div class="title">申报老人:{{item.Name}}</div>
          <div class="flex">
            <div>身份证号：{{item.IDCard}}</div>
          </div>
          <div class="flex">
            <div style="width:65%;">联系电话：{{item.TouchTel}}</div>
            <div style="width:30%;">性别：{{item.SexDesc}}</div>
          </div>
          <div style="padding:5px 10px;font-size:15px;">养老需求：{{item.DemandDesc}}</div>
          <div style="padding:5px 10px;font-size:15px;">现住址：{{item.Address}}</div>
          <div style="padding:5px 10px;font-size:15px;"
            v-if="item.Status == 3">未通过原因：{{item.ExamArea}}</div>
          <div class="Status"
            style="background: #e5a66e;"
            v-if="item.Status == 2">{{item.StatusDesc}}</div>
          <div class="Status"
            style="background: #02c08f;"
            v-if="item.Status == 3">{{item.StatusDesc}}</div>
          <div class="Status"
            style="background: #e6776f;"
            v-if="item.Status == 1">{{item.StatusDesc}}</div>

        </div>
      </div>
    </div>
    <van-empty v-if="zanwu"
      description="暂无数据" />
  </div>
</template>
  
<script>
import { WeGetOldManApply } from "@/api/oldman";
import axios from "axios";
export default {
  data () {
    return {
      active: 0,
      filters: {
        status: 1,
        limit: 999,
      },
      countList: [],//服务列表
      zanwu: false,//暂无数据
    }
  },
  created () {
    // 获取提交记录
    this.getOldManApply()
  },
  methods: {
    // tab选择器
    onClick (name, title) {
      this.filters.status = name
      this.countList = []
      this.getOldManApply()
    },
    // 
    getOldManApply () {
      this.filters.openID = this.$route.query["openid"];

      // WeGetOldManApply(this.filters).then((res) => {
      axios({
        method: "GET",
        url: 'https://zkxcylapi.anluoyun.cn/api/OldmanApply/WeGetOldManApply',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params: {
          openID: this.filters.openID,
          status: this.filters.status,
          limit: 999,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.length != 0) {
            this.countList = res.data.data
            this.zanwu = false;
          } else {
            this.zanwu = true;//暂无数据
          }

        }
      })
    },
  }
}
</script>
  
<style scoped>
/* 列表 */
.lists {
  padding-bottom: 60px;
}
.list {
  position: relative;
  width: 90%;
  margin: 10px 5% 0px;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  box-shadow: 0 0 5px #e8e1e1;
}

.list .title {
  padding: 5px 10px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 15px;
  color: #333;
  padding: 5px 10px;
}
.list .flex text:nth-child(1) {
  width: 40%;
}
.list .flex text:nth-child(2) {
  width: 55%;
}
.list .flex text {
  flex-grow: 1;
  /* flex-shrink: 1; */
}
.list .Status {
  position: absolute;
  top: 0px;
  right: 10px;
  background: #4b8cff;
  padding: 5px 0px;
  color: #fff;
  width: 62px;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>